import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { StyledEngineProvider } from '@mui/material/styles';
import Home from './routes/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppContainer from './AppContainer';
import BusinessDetail from './routes/BusinessDetail';
import { getVenues } from './api/venues';

(async () => {
  const businesses = await getVenues();
  console.log(businesses);

  ReactDOM.render(
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AppContainer />}>
              <Route path="" element={<Home businesses={businesses} />} />
              <Route
                path="spot/:spotSlug"
                element={<BusinessDetail businesses={businesses} />}
              />
              <Route path="*" element={<h1>Not Found</h1>} />
            </Route>
          </Routes>
        </BrowserRouter>
      </StyledEngineProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

export const businessNameToSlug = (businessName) => {
  return businessName
    .split(' ')
    .map((word) => word.replaceAll(/[^a-zA-Z0-9]/g, '').toLowerCase())
    .join('-');
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
