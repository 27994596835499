import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import { businessNameToSlug } from "../..";
import { display } from "@mui/system";
import SideScrollingCards from "../../Components";
import { Button } from "@mui/material";

const Home = (props) => {
  const renderBusinesses = () => {
    return props.businesses.map((business) => {
      return (
        <Link
          to={`/spot/${businessNameToSlug(business.name)}`}
          style={{
            textDecoration: "none",
            float: "left",
            display: "flex",
            margin: "10px",
          }}
          key={business.name}
        >
          <Card sx={{ mb: 5, boxShadow: 6, height: "100%" }}>
            <CardMedia
              component="img"
              height="194"
              image="paella.jpg"
              alt="Paella dish"
            />
            <CardContent>
              <Typography variant="h5" component="div">
                {business.name}
              </Typography>
              {/* <Box sx={{ mt: 1 }}>
                {business.tags.map((tag) => {
                  return <Chip key={tag} sx={{ mr: 1 }} label={tag} />;
                })}
              </Box> */}
              <Typography variant="body2" sx={{ mt: 2 }}>
                {business.address}
              </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                {business.phone_number}
              </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                <pre>{business.hours}</pre>
              </Typography>
            </CardContent>
          </Card>
        </Link>
      );
    });
  };

  return (
    <>
      <Button variant="contained">Recommended based on your profile</Button>
      <SideScrollingCards cards={renderBusinesses()} />
      <Button variant="contained">Great events near you tonight</Button>
      <SideScrollingCards cards={renderBusinesses()} />
      <Button variant="contained">
        Explore the best margarita in your area
      </Button>
      <SideScrollingCards cards={renderBusinesses()} />
    </>
  );
};

export default Home;
