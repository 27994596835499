import styled from "@emotion/styled";

const SidescrollingContainer = styled("div")({
  overflowX: "auto",
  overflowY: "hidden",
  whiteSpace: "nowrap",
  display: "flex",
});

const SideScrollingCards = (props) => {
  return <SidescrollingContainer>{props.cards}</SidescrollingContainer>;
};

export default SideScrollingCards;
