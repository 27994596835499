import React from "react";
import styled from "styled-components";
import { Map, Marker } from "react-map-gl";

import "mapbox-gl/dist/mapbox-gl.css";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { businessNameToSlug } from "..";

// Ask Bryan for API Token
const MAPBOX_TOKEN = "AskBryanForToken";

const Header = styled.header`
  color: Black;
  font-size: 35px;
  padding: 10px 60px;
  border-radius: 5px;
  margin: 10px 0px;
`;

function DoubleCard(props) {
  return (
    <Grid container spacing={2}>
      <Card sx={{ width: "50%" }}>
        <CardContent>
          <Typography variant="h5" component="div">
            # Tags (sorted by most upvoted)
          </Typography>
          <Typography variant="body2">
            Secret Fried Chicken (1000 upvotes)
            <br />
            Cocktails (420 upvotes)
            <br />
            Date Night (69 upvotes)
            <br />
            Stuff (50 upvotes)
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ width: "50%" }}>
        <CardContent>
          <Typography variant="h5" component="div">
            Business Information
          </Typography>
          <Typography variant="body2">
            <b>Address:</b> {props.business.address}
            <br />
            <b>Hours:</b>
            <pre>{props.business.hours}</pre>
          </Typography>
          <br />
          <a href={props.business.website} rel="businesslink">
            {props.business.name}
          </a>
        </CardContent>
        <CardActions>
          <Button size="medium">Not accurate? Tell us</Button>
        </CardActions>
      </Card>
    </Grid>
  );
}

function UpcomingEvents() {
  return (
    <Card sx={{ width: "100%" }}>
      <CardContent>
        <Typography variant="h5" component="div">
          Upcoming Events
        </Typography>
        <Typography variant="body2">
          Wednesday - Fried Chicken and Waffles Night
          <br />
          {'"$99.99 for a party of 4"'}
        </Typography>
        <br />
        <Typography variant="body2">
          Tuesday Trivia - Geeks who Drink
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="medium">Not accurate? Tell us</Button>
      </CardActions>
    </Card>
  );
}

function LikeDislike() {
  return (
    <Grid container spacing={2}>
      <Card sx={{ width: "50%" }}>
        <CardActions>
          <Button size="medium">I like this spot!</Button>
        </CardActions>
      </Card>
      <Card sx={{ width: "50%" }}>
        <CardActions>
          <Button size="medium">I don't care for it.</Button>
        </CardActions>
      </Card>
    </Grid>
  );
}

function Mapbox() {
  return (
    <Map
      initialViewState={{
        latitude: 42.37455128310839,
        longitude: -71.10158443086553,
        zoom: 14,
      }}
      style={{ width: 530, height: 300 }}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      mapboxAccessToken={MAPBOX_TOKEN}
    >
      <Marker
        longitude={-71.10158443086553}
        latitude={42.374551283108398}
        color="red"
      />
    </Map>
  );
}

function AddTag() {
  return (
    <Card sx={{ width: "100%" }}>
      <TextField id="outlined-basic" label="Add a #tag" variant="standard" />
    </Card>
  );
}

const BusinessDetail = (props) => {
  const params = useParams();
  const { spotSlug } = params;
  const business = props.businesses.filter(
    (business) => businessNameToSlug(business.name) === spotSlug
  )[0];

  return (
    <div>
      <Header>{business.name}</Header>
      <DoubleCard business={business} />
      <br />
      <UpcomingEvents business={business} />
      <br />
      <AddTag business={business} />
      <br />
      <br />
      <LikeDislike business={business} />
      <br />
      <Mapbox business={business} />
    </div>
  );
};

export default BusinessDetail;
